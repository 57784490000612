export default {
    getGame: function (dbHandle,options) {

        if (0) {
            console.log('getgame');
            var dbg = dbHandle.prepare("SELECT count(*) FROM puzzles");
            var dbgr = dbg.getAsObject({});
            console.log(dbgr);
        }

        if ( options.puzzleId ) {
            var data = dbHandle.prepare( "SELECT * FROM puzzles WHERE id in (" + options.puzzleId.join(',') + ")" );
        } else {
            var sql = "SELECT * FROM puzzles WHERE ";
            var ands = [];

            if ( options.diff == 1 ) {
                ands.push(" (diff>=200 AND tokens<=3) ");
            } else if ( options.diff == 2 ) {
                ands.push(" (diff>0 AND tokens>0) ");
            } else if ( options.diff == 3 ) {
                ands.push(" (tokens>3) ");
            }

            if ( localStorage.getItem("puzzleHistory") ) {
                var puzzleHistory = JSON.parse(localStorage.getItem("puzzleHistory"));
                ands.push(" id NOT IN (" + puzzleHistory.join(',') + ") ");
            }

            // if ( options.appMode == 'native' && options.paidMode == 'free' ) {
            //     ands.push(" free=1 ");
            // }

            sql = sql + ands.join(" AND ");
            sql = sql + " ORDER BY RANDOM() ";
            //sql = sql + " LIMIT " + options.ppr;
            //console.log('sql', sql);
        }

        var puzzles = [];
        //console.log(sql);
        var data = dbHandle.prepare(sql);
        while ( data.step() ) {
            puzzles.push( data.getAsObject() );
        };

        let puzzlesShuffled = puzzles
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
        
        return puzzlesShuffled.slice(0, options.ppr);
    }
}
