<template>

<div id="userhud" class="userhud mb-1" :class="{ 'anim slide-out-right' : switchUserAnim==1, 'anim slide-in-left' : switchUserAnim==2 }" >
        <div class="position-relative fs-4 d-flex align-items-start justify-content-between">
            <div class="logo-main text-white text-decoration-none" :class="{ 'logo-grow' : $root.logoGrow==1, 'logo-shrink' : $root.logoGrow==2 }" v-touch:tap="clickLogo">
                <img class="my-1" :class="{ 'd-none' : $root.ttsActive }" src="@/assets/gfx/logo1.png"  />
                
                <img class="my-1" :class="{ 'd-none' : !$root.ttsActive, 'd-block' : $root.ttsActive }" src="@/assets/gfx/logo1-tts.png" @animationend="logoGrowAnim()" />
            </div>

            <div class="w-100 d-flex align-items-start justify-content-end fs-3 pe-1">
                    <div v-if="0" @click="openDebug()">D</div>
                    <a class="px-1 fs-3 text-decoration-none clickfx"  href="#" @click="$root.view('player');">
                        <img class='icon-avatar' :src="$root.avatarLib[userStore.user.avatarLevel][userStore.user.avatar]" />
                    </a>
                    
                    <div class="d-flex flex-column">
                        <a class="fs-5 text-decoration-none text-nowrap clickfx"  href="#" @click="$root.view('player');">{{ userStore.user.name }}</a>

                        <div class="mt-1 d-flex justify-content-end awards-container" v-if="$root.calcAwards()">
                            <template v-for="awd in $root.calcAwards()">
                                <i v-if="$root.calcAwardsTotal()<=12" v-for="idx in awd.qty" class="fs-4" :class="awd.icon" :style="'color: ' + this.gameStore.options.ppr[awd.ppr].color"></i>

                                <div v-if="$root.calcAwardsTotal()>12" class="d-flex align-items-center ms-2">
                                    <i class="fs-4" :class="awd.icon" :style="'color: ' + this.gameStore.options.ppr[awd.ppr].color"></i> 
                                    <span class="fs-6" :style="'color: ' + this.gameStore.options.ppr[awd.ppr].color">{{ awd.qty }}</span>
                                </div>
                            </template>
                        </div>
                    </div>
            </div>
        </div>

        <div class="toolbar mt-2 w-100 ps-1 pe-1 d-flex align-items-center justify-content-end fs-4">

            <div class="badge rounded-pill bg-yellow text-dark d-flex flex-column justify-content-center align-items-center me-2" @click="activateTool(userStore.user.tools.unmask)">
                <span class="me-1">
                    {{ this.$root.numFormat(userStore.user.tools.unmask.total) }}
                </span>
                <img class="icon-unmask" :src="userStore.user.tools.unmask.icon" :class="{ 'anim pulsate-fwd active' : userStore.user.tools.unmask.active }" />
            </div>

            <div class="badge rounded-pill bg-yellow text-dark d-flex flex-column justify-content-center align-items-center me-2" @click="activateTool(userStore.user.tools.shield)">
                <span class="me-1">
                    {{ this.$root.numFormat(userStore.user.tools.shield.total) }}
                </span>
                <img class="icon-shield" :src="userStore.user.tools.shield.icon" :class="{ 'anim pulsate-fwd active' : userStore.user.tools.shield.active }" />
            </div>

            <div class="badge rounded-pill bg-yellow text-dark d-flex flex-column justify-content-center align-items-center me-2" @click="activateTool(userStore.user.tools.spotlight)">
                <span class="me-1">
                    {{ this.$root.numFormat(userStore.user.tools.spotlight.total) }}
                </span>
                <img class="icon-spotlight" :src="userStore.user.tools.spotlight.icon" :class="{ 'anim pulsate-fwd active' : userStore.user.tools.spotlight.active }" />
            </div>

            <div class="badge rounded-pill bg-yellow text-dark d-flex flex-column justify-content-center align-items-center me-2" @click="activateTool(userStore.user.tools.wand)">
                <span class="me-1">
                    {{ this.$root.numFormat(userStore.user.tools.wand.total) }}
                </span>
                <img class="icon-wand" :src="userStore.user.tools.wand.icon" :class="{ 'anim pulsate-fwd active' : userStore.user.tools.wand.active }" />
            </div>

            <a href="#" class="px-4 badge rounded-pill bg-yellow text-dark d-flex flex-column justify-content-center align-items-center text-decoration-none text-dark" :class="{ 'clickfx' : userStore.user.totals.score }" @click="goShop()">
                <span class="me-1">
                    {{ $root.numFormat(parseInt(userStore.user.totals.score)) }}
                </span>
                <img class="icon-spotlight" :src="userStore.user.tools.coin.icon" />
            </a>
        </div>
    </div>

    <div id="debugModal" class="d-flex flex-column align-items-center justify-content-center" v-if="showDebug" @click="showDebug=0">
        <pre>
            {{ JSON.stringify(debugData, null, 2) }}
        </pre>
    </div>

    <alert-dialog ref="alertDialog"></alert-dialog>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
</template>

<script>
import { useUserStore,useGameStore } from '@/gamestore.js';
import confirmDialog from '@/components/confirmdialog.vue';
import alertDialog from '@/components/alertdialog.vue';

export default {
  name: 'userhud',
  components: { 
        confirmDialog, alertDialog 
    },
  props: {

  },
  data: () => ({   
      userStore: useUserStore(),
      gameStore: useGameStore(),
      switchUserAnim: 0,
      showDebug: 0,
      debugData: {},
  }),
  methods: {
    openDebug: function() {
        var data = {
            'safe-area-inset-top': window.getComputedStyle(document.body).getPropertyValue('--safe-area-inset-top'),
            'safe-area-inset-bottom': window.getComputedStyle(document.body).getPropertyValue('--safe-area-inset-bottom'),
            'safe-area-inset-left': window.getComputedStyle(document.body).getPropertyValue('--safe-area-inset-left'),
            'safe-area-inset-right': window.getComputedStyle(document.body).getPropertyValue('--safe-area-inset-right'),
            'appDimensions': this.$root.appDimensions(),
            'progress-wrapper': document.getElementById('progress-wrapper')?document.getElementById('progress-wrapper').offsetHeight:0,
            'phraseWinMarker': document.getElementById('phraseWinMarker')?document.getElementById('phraseWinMarker').offsetTop:0,
            'scrollArea': window.getComputedStyle(document.getElementById('app')).getPropertyValue('--phrase-desc-h'),
        }

        this.debugData = data;
        this.showDebug = 1;
    },
    clickLogo: function() {
        //console.log('clickLogo');
        if ( this.$root.page == 'setup' ) {
            if ( !this.userStore.users.welcome ) {
                if ( this.$root.sfxObj.welcome ) {
                    //console.log('here1');
                    if ( this.$root.sfxObj.welcome.playing() ) {
                        //console.log('here2');
                        this.$root.sfxObj.welcome.stop();
                        return;
                    } 
                }
                //console.log('here3');
                this.$root.sfxObj.welcome = this.$root.playSfx(this.$root.sfxObj.welcome,this.$root.sfxLib.speech.welcome,{
                    tts:1,
                    logoGrow: 1,
                    callback:function(self){
                        //console.log('endplaycallback');
                        self.$root.logoGrow = 2;
                        
                    }
                });
            }
        } else {
            this.$root.view('setup');
        }
    },
    logoGrowAnim: function() {
        if ( this.$root.logoGrow == 2 ) {
            this.$root.logoGrow = 0;
        }
    },  
    activateTool: function(tool) {
        if ( !this.gameStore.currentGame.gameboard.isStart ) {
            this.$root.sfxObj.notice = this.$root.playSfx(this.$root.sfxObj.notice,this.$root.sfxLib.speech.notice,{tts:1});
            
            this.noGame(tool);
            return;
        }

        if ( this.gameStore.currentGame.gameboard.isStart && this.gameStore.currentGame.gameboard.isReady && tool.total == 0 ) {
            this.$root.sfxObj.notready = this.$root.playSfx(this.$root.sfxObj.notready,this.$root.sfxLib.speech.notready,{tts:1});

            this.emptyTool(tool);
            return;
        }

        if ( this.gameStore.currentGame.gameboard.isStart && this.gameStore.currentGame.gameboard.isReady && tool.total > 0 ) {
            if ( tool.active ) {
                if ( tool.sound.off ) {
                    this.$root.playSfx(this.$root.sfxObj.tmp,tool.sound.off,{});
                }
                tool.active = 0;
            } else if ( !tool.active ) {
                if ( tool.sound.on ) {
                    this.$root.playSfx(this.$root.sfxObj.tmp,tool.sound.on,{});
                }
                tool.active = 1;

                if ( tool.id == 'shield' ) {
                    this.confirmShield();                    
                }

                if ( tool.id == 'wand' ) {
                    this.confirmWand();                    
                }

                if ( tool.id == 'unmask' ) {
                    this.confirmUnmask();                    
                }

                if ( tool.id == 'spotlight' ) {
                    this.confirmSpotlight();                    
                }
            }
        }
    },
    async noGame(tool) {
        const ok = await this.$refs.alertDialog.show({
            title: "<span>Lesson</span>",
            message: "<img src='"+tool.icon+"' style='height:50px;' /><h3 class='m-0'>"+tool.name+"</h3><p class='p-0'>"+tool.description+"</p><p class='p-0'>Use this tool while solving a puzzle.</p>",
        });
        return;
    },
    async goShop() {
        if ( this.userStore.user.totals.score ) {
            this.$root.view('shop');
            return;
        } else {
            const ok = await this.$refs.alertDialog.show({
                title: "<span class='text-danger text-uppercase' style='text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.75);'>No Coins</span>",
                message: "<h3 class='m-0'>Earn coins by solving puzzles and then you can shop for tools.</h3>",
            });
        }
    },
    async emptyTool(tool) {
            const ok = await this.$refs.alertDialog.show({
                title: "<span class='text-danger text-uppercase' style='text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.75);'>None Available</span>",
                message: "<img src='"+tool.icon+"' style='height:50px;' /><h3 class='m-0'>"+tool.name+"</h3><p class='p-0'>"+tool.description+"</p><p class='p-0'>Earn more coins and then go to the shop.</p><img class='icon-spotlight' src='"+this.userStore.user.tools.coin.icon+"' />",
            });
            return;
    },
    async confirmShield() {
        const ok = await this.$refs.confirmDialog.show({
            title: this.userStore.user.tools.unmask.name,
            message: this.userStore.user.tools.shield.description,
            okButton: 'Nope',
            cancelButton: 'Use it',
        });
        if (ok) {
            //bail
            this.userStore.user.tools.shield.active = 0;
        } 
    },
    async confirmWand() {
        const ok = await this.$refs.confirmDialog.show({
            title: this.userStore.user.tools.unmask.name,
            message: this.userStore.user.tools.wand.description,
            okButton: 'Nope',
            cancelButton: 'Use it',
        });
        if (ok) {
            //bail
            this.userStore.user.tools.wand.active = 0;
        } else {
            //ok
            this.$parent.solveWord();
        }
    },
    async confirmUnmask() {
        const ok = await this.$refs.confirmDialog.show({
            title: this.userStore.user.tools.unmask.name,
            message: this.userStore.user.tools.unmask.description,
            okButton: 'Nope',
            cancelButton: 'Use it',
        });
        if (ok) {
            //bail
            this.userStore.user.tools.unmask.active = 0;
        } else {
            //ok
            this.$parent.unmaskSource();
        }
    },
    async confirmSpotlight() {
        const ok = await this.$refs.confirmDialog.show({
            title: this.userStore.user.tools.unmask.name,
            message: this.userStore.user.tools.spotlight.description,
            okButton: 'Nope',
            cancelButton: 'Use it',
        });
        if (ok) {
            //bail
            this.userStore.user.tools.spotlight.active = 0;
        } else {
            //ok
            this.$parent.enableSpotlight();
        }
    }
  },
}
</script>

<style>
.toolbar {
    transform: scale(0.8);
    transform-origin: right;
}

#debugModal {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgba(255,255,255,0.9);
    z-index:10000;
}

</style>
