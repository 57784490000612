<template>

    <div class="">

        <userhud />

        <div class="mb-4">
            <div class="fs-4 p-1 d-flex align-items-center justify-content-center bg-black text-white">
                <div class="ps-1 text-uppercase">
                    collect cool tools
                </div>  
            </div>
        </div>
        
        <div class="px-2">

            <div class="d-flex align-items-start justify-content-between mt-3 mt-md-1">
                <div class="text-uppercase d-flex align-items-start justify-content-start">
                    <div class="icon-col">
                        <a class="ms-2 text-decoration-none clickfx"  href="#" @click="buyTool('unmask',qty.unmask)">
                            <img class="icon-unmask icon-small" src="@/assets/gfx/binocular.png" />
                        </a> 
                    </div>
                    <div class="">
                        <a class="fs-6 text-decoration-none clickfx" href="#" @click="buyTool('unmask',qty.unmask)">
                            {{ userStore.user.tools.unmask.name }}
                            <p class="text-dark" style="font-size:80%">{{ userStore.user.tools.unmask.description }}</p>
                        </a>
                    </div>
                </div>
                <div class="text-primary text-uppercase fs-4 d-flex align-items-center">
                    <select class="me-4 text-primary" v-model="qty.unmask">
                        <template v-for="idx in 10">
                            <option :value="idx">{{ idx }}</option>
                        </template>
                    </select>
                    <div class="d-flex align-items-center justify-content-end" @click="buyTool('unmask',qty.unmask)">
                        <span class="me-2">{{ this.$root.numFormat(userStore.user.tools.unmask.price) }}</span>
                        <i class="icon-image icon-coin icon-small"></i>
                    </div>
                </div>
            </div>

            <div class="d-flex align-items-start justify-content-between mt-3 mt-md-1 mt-md-1">
                <div class="d-flex text-uppercase align-items-start justify-content-start">
                    <div class="icon-col">
                        <a class="ms-2 text-decoration-none clickfx"  href="#" @click="buyTool('shield',qty.shield)">
                            <img class="icon-shield icon-small" src="@/assets/gfx/shield.png" />
                        </a> 
                    </div>
                    <div class="">
                        <a class="fs-6 text-decoration-none clickfx" href="#" @click="buyTool('shield',qty.shield)">
                            {{ userStore.user.tools.shield.name }}
                            <p class="text-dark" style="font-size:80%">{{ userStore.user.tools.shield.description  }}</p>
                        </a>
                    </div>
                </div>
                <div class="text-primary text-uppercase fs-4 d-flex align-items-center">
                    <select class="me-4 text-primary" v-model="qty.shield">
                        <template v-for="idx in 10">
                            <option :value="idx">{{ idx }}</option>
                        </template>
                    </select>
                    <div class="d-flex align-items-center justify-content-end" @click="buyTool('shield',qty.shield)">
                        <span class="me-2">{{ this.$root.numFormat(userStore.user.tools.shield.price) }}</span>
                        <i class="icon-image icon-coin icon-small"></i>
                    </div>
                </div>
            </div>

            <div class="d-flex align-items-start justify-content-between mt-3 mt-md-1">
                <div class="text-uppercase d-flex align-items-start justify-content-start">
                    <div class="icon-col">
                        <a class="ms-2 text-decoration-none clickfx"  href="#" @click="buyTool('spotlight',qty.spotlight)">
                            <img class="icon-spotlight icon-small" src="@/assets/gfx/magnifyglass.png" />
                        </a> 
                    </div>
                    <div class="">
                        <a class="fs-6 text-decoration-none clickfx" href="#" @click="buyTool('spotlight',qty.spotlight)">
                            {{ userStore.user.tools.spotlight.name }}
                            <p class="text-dark" style="font-size:80%">{{ userStore.user.tools.spotlight.description }}</p>
                        </a>
                    </div>
                </div>
                <div class="text-primary text-uppercase fs-4 d-flex align-items-center">
                    <select class="me-4 text-primary" v-model="qty.spotlight">
                        <template v-for="idx in 10">
                            <option :value="idx">{{ idx }}</option>
                        </template>
                    </select>
                    <div class="d-flex align-items-center justify-content-end" @click="buyTool('spotlight',qty.spotlight)">
                        <span class="me-2">{{ this.$root.numFormat(userStore.user.tools.spotlight.price) }}</span>
                        <i class="icon-image icon-coin icon-small"></i>
                    </div>
                </div>
            </div>

            <div class="d-flex align-items-start justify-content-between mt-3 mt-md-1">
                <div class="text-uppercase d-flex align-items-start justify-content-start">
                    <div class="icon-col">
                        <a class="ms-2 text-decoration-none clickfx"  href="#" @click="buyTool('wand',qty.wand)">
                            <img class="icon-wand icon-small" src="@/assets/gfx/wand.png" />
                        </a> 
                    </div>
                    <div class="">
                        <a class="fs-6 text-decoration-none clickfx" href="#" @click="buyTool('wand',qty.wand)">
                            {{ userStore.user.tools.wand.name }}
                            <p class="text-dark" style="font-size:80%">{{ userStore.user.tools.wand.description }}</p>
                        </a>
                    </div>
                </div>
                <div class="text-primary text-uppercase fs-4 d-flex align-items-center">
                    <select class="me-4 text-primary" v-model="qty.wand">
                        <template v-for="idx in 10">
                            <option :value="idx">{{ idx }}</option>
                        </template>
                    </select>
                    <div class="d-flex align-items-center justify-content-end" @click="buyTool('wand',qty.wand)">
                        <span class="me-2">{{ this.$root.numFormat(userStore.user.tools.wand.price) }}</span>
                        <i class="icon-image icon-coin icon-small"></i>
                    </div>
                </div>
            </div>

            <div v-if="userStore.user.avatarLevel==0" class="d-flex align-items-start justify-content-between mt-3 mt-md-1">
                <div class="text-uppercase d-flex align-items-start justify-content-start">
                    <div class="icon-col">
                        <a class="text-decoration-none clickfx" style="width:5.25rem;" href="#" @click="buyAvatar()">
                            <img class='icon-avatar' :src="$root.avatarLib[1][userStore.user.avatar]" />
                        </a>
                    </div>
                    <div class="">
                        <a class="fs-6 text-decoration-none clickfx" href="#" @click="buyAvatar()">
                            Avatar
                            <p class="text-dark" style="font-size:80%">{{ userStore.user.tools.avatar.description }}</p>
                        </a>
                    </div>
                </div>
                <div class="text-primary text-uppercase fs-4 d-flex align-items-center">
                    <div class="d-flex align-items-center justify-content-end" @click="buyAvatar()">
                        <span class="me-2">{{ this.$root.numFormat(userStore.user.tools.avatar.price) }}</span>
                        <i class="icon-image icon-coin icon-small"></i>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="gameStore.currentGame.inProgress" class="mt-2 mt-md-2 text-center">
            <button type="button" class="tron sm" @click="gameStore.currentGame.resume=1;$root.view('play')">
                <span></span><span></span><span></span><span></span>Back to Game
            </button>
        </div>
        <div v-else class="mt-2 mt-md-2 text-center">
            <button type="button" class="tron sm" @click="$root.view('setup')">
                <span></span><span></span><span></span><span></span>New Game
            </button>
        </div>
    </div>

    <alert-dialog ref="alertDialog"></alert-dialog>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>

</template>

<script>
import userhud from "@/components/userhud.vue";
import { useUserStore,useGameStore } from '@/gamestore.js';
import alertDialog from '@/components/alertdialog.vue';
import confirmDialog from '@/components/confirmdialog.vue';

function randomColor(brightness){
  function randomChannel(brightness){
    var r = 255-brightness;
    var n = 0|((Math.random() * r) + brightness);
    var s = n.toString(16);
    return (s.length==1) ? '0'+s : s;
  }
  return '#' + randomChannel(brightness) + randomChannel(brightness) + randomChannel(brightness);
}

export default {
    components: { 
        userhud, alertDialog, confirmDialog
    },
    data: () => ({  
        userStore: useUserStore(),
        gameStore: useGameStore(),
        selectAvatarStyle: 0,
        avatarStyles: {},
        qty: {
            shield: 1,
            wand: 1,
            unmask: 1,
            spotlight: 1,
        }
    }),
    computed: {

    },
    methods: {
        startGame: function(){
            this.$root.view('play');
        },
        async buyTool(tool,qty) {
            var price = this.userStore.user.tools[tool].price * this.qty[tool];

            if ( price >= this.userStore.user.totals.score ) {
                const ok = await this.$refs.alertDialog.show({
                    title: "<span class='text-danger text-uppercase' style='text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.75);'>Sorry</span>",
                    message: "You don't have enough coins for this - play more and earn more!",
                });

                return;
            }

            const ok = await this.$refs.confirmDialog.show({
                title: "<span class='text-uppercase' style='text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.75);'>" + "Buy " + this.qty[tool] + " " + this.userStore.user.tools[tool].name + "(s)" + "</span>",
                message: 'You will spend ' + price  + ' coins. OK?',
                okButton: 'No',
                cancelButton: 'Yes',
            });
            if (ok) {
                //bail
            } else {
                //real ok
                this.userStore.user.tools[tool].total += this.qty[tool];
                this.userStore.chargeUser( price );
                this.$root.playSfx(this.$root.sfxObj.tmp,this.$root.sfxLib.buy,{});
            }
        },        
        async buyAvatar() {
            var price = this.userStore.user.tools.avatar.price;

            if ( price >= this.userStore.user.totals.score ) {
                const ok = await this.$refs.alertDialog.show({
                    title: "<span class='text-danger text-uppercase' style='text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.75);'>Sorry</span>",
                    message: "You don't have enough coins for this - play more and earn more!",
                });

                return;
            }

            const ok = await this.$refs.confirmDialog.show({
                title: "<span class='text-uppercase' style='text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.75);'>" + "Bring your avatar to life." + "</span>",
                message: 'You will spend ' + price  + ' coins. OK?',
                okButton: 'No',
                cancelButton: 'Yes',
            });
            if (ok) {
                //bail
            } else {
                //real ok
                this.userStore.user.avatarLevel = 1;
                this.userStore.chargeUser( price );
                this.$root.playSfx(this.$root.sfxObj.tmp,this.$root.sfxLib.buy,{});
            }
        },
    },
    mounted() {
        this.gameStore.currentGame.gameboard.isStart = 0;
    },
}

</script>

<style>
.select-avatar-style {
    width: 90%;
}

.colorSwatch {
    height: 30px;
}
</style>
