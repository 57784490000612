<template>
    <div id="footer" class="w-100">
      <div class="footer w-100">
            <div v-if="!$parent.isPaid()" class="px-4 py-1 fs-5 d-flex align-items-center justify-content-between bg-yellow">
              <a href="#" class="text-decoration-none clickfx" @click="$root.view('upgrade')">
                <div class="d-flex align-items-center">
                  <span class="text-danger fs-2 anim jello-horizontal">{{ freePlays() }}</span>
                  <span class="ms-1">
                    <span v-if="freePlays()==1">free play</span>
                    <span v-else>free plays</span>
                  </span>
                </div>
              </a>
              <a href="#" class="text-decoration-none clickfx" @click="$root.view('upgrade')">
                <div class="d-flex align-items-center">
                  <span>upgrade now</span>
                  <i class="ms-1 fs-2 game-icon game-icon-play-button"></i>
                  <i class="fs-2 game-icon game-icon-play-button"></i>
                </div>
              </a>
            </div>          
            <div class="px-2 py-0 d-flex justify-content-between align-items-center text-white text-uppercase">
              <span>Just Saying - Movie Quotes</span>
              <a href="#" class="ms-2 text-decoration-none text-white clickfx" @click="this.$root.view('about')"><img src="@/assets/gfx/justsaying-about.jpg" style="height:30px;border-radius:20px;box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" /></a>
            </div>
        </div>
    </div>
</template>

<script>
import { useUserStore } from '@/gamestore.js';

  export default {
    name: 'myfooter',
    props: {

    },
    data: () => ({   
      userStore: useUserStore(),
    }),
    methods: {
      freePlays() {
        return this.userStore.users.freeplays;
      }
    },
  }
</script>

<style>
#footer { 
    position:absolute;
    bottom: 0;
    width:100%;
}

#footer .footer {
  background-color: var(--bs-primary);
}

#freebanner {
  background-color: #FFFF00;
}
</style>