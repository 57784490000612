import './assets/css/bootstrap.custom.scss';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { piniaCapacitorPersist } from '@/pinia-capacitor.js';
//import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { SplashScreen } from '@capacitor/splash-screen';
import { TextZoom } from "@capacitor/text-zoom";
import Vue3TouchEvents from "vue3-touch-events";
import { SafeArea } from '@capacitor-community/safe-area';

import App from './App.vue';
import { useUserStore } from '@/gamestore.js';
import { createHead } from 'unhead';
import { useHead } from 'unhead';
import {Howl, Howler} from '@/howler.js';
import './assets/css/global.css';
import './assets/css/anim.css';
import './assets/css/game-icons.css';

import "cordova-plugin-purchase";

import { Device } from '@capacitor/device';

const pinia = createPinia();
pinia.use(piniaCapacitorPersist);

createHead();
const app = createApp(App);
app.use(pinia).use(Vue3TouchEvents);

import initSqlJs from "sql.js";
initSqlJs({
  locateFile: file => `/assets/sql-wasm.wasm`
}).then(function(SQL){
  const db = new SQL.Database();
  const xhr = new XMLHttpRequest();
  xhr.open('GET', '/assets/gamedata.db', true);
  xhr.responseType = 'arraybuffer';
  xhr.onload = e => {
    const uInt8Array = new Uint8Array(xhr.response);
    const db = new SQL.Database(uInt8Array);
    app.config.globalProperties.dbHandle = db;
  };
  xhr.send();
});

async function webSetup() {
  kickoff();return;

  let info = await Device.getInfo();
  if ( info.operatingSystem.match(/android/i) || info.operatingSystem.match(/ios/i) ) {
    location = "/mobile.html";
  } else {
    kickoff();
  }
}

async function kickoff() {
  const userStore = useUserStore();
  await userStore.restored;
  let info = await Device.getInfo();

  if ( location.search == '?reset' ) {
    userStore.resetUsers('new');
  }

  if ( location.search == '?resetdebug' ) {
    userStore.resetUsers('debug');
  }

  if ( Capacitor.isNativePlatform() ) {
    userStore.users.appMode = 'native';
    userStore.users.appPlatorm = info.operatingSystem.match(/ios/i) ? 'ios' : 'android';
  } else {
    userStore.users.appMode = 'web';
    userStore.users.appPlatorm = info.operatingSystem;
  }

  app.mount('#app');

  useHead({
    title: "Just Saying - Movie Quotes",
    meta: [
      { name: 'description', content: "Test your knowledge of quotes from famous movies" },
    ], 
  });
}

SafeArea.enable({
  config: {
    customColorsForSystemBars: true,
    statusBarColor: '#000000', 
    statusBarContent: 'dark',
    navigationBarColor: '#000000',
    navigationBarContent: 'dark',
  },
});

await SplashScreen.hide();
await SplashScreen.show({
  showDuration: 1000,
  autoHide: true,
  androidScaleType: 'FIT_CENTER',
});

document.addEventListener('deviceready', function(){
  TextZoom.set({value: 1.0}); 
});

if ( Capacitor.isNativePlatform() ) {
  kickoff();
} else {
  webSetup();
}