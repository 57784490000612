<template>

    <div class="">

        <div class="mb-4">
            <div class="fs-4 p-1 d-flex align-items-center justify-content-center bg-black text-white">
                <div class="ps-1 text-uppercase">
                    unlock all the puzzles
                </div>
            </div>
        </div>

        <div class="m-4 fs-5 text-black">
            <p>Thanks for playing the free edition of Just Saying - Movie Quotes.</p>
            <p>Now that you've enjoyed some free plays, you can upgrade to unlock unlimited games forever!</p>
        </div>

        <div class="m-2 fs-4 text-primary">
            <h2 class="m-2">Upgrade to the full edition with a one-time purchase.</h2>
            <ul class="fs-4">
                <li>Over 500+ puzzles and growing</li>
            </ul>
        </div>

        <div class="m-5 card text-center" v-if="$parent.IAP?.title">
            <div class="card-header text-uppercase">
                Upgrade Details
            </div>
            <div class="card-body">
                <h5 class="card-title">{{ $parent.IAP.title }}</h5>
                <p class="card-text">{{ $parent.IAP.description }}</p>
                <p class="card-text">{{ $parent.IAP.pricing.price }} {{ $parent.IAP.pricing.currency }}</p>
            </div>
            <div class="card-footer text-muted">
                <button class="btn btn-primary btn-sm text-uppercase clickfx" @click="$parent.kickoffIAP()">Upgrade</button>
                <p class="mt-3 d-flex flex-column align-items-center">
                    <a href="#" class="text-decoration-none clickfx" @click="$parent.view('upgrade_restore')">Already purchased full version?</a>
                </p>
            </div>
        </div>
        <div v-else class="m-4 text-secondary">
            Uh-oh: cannot connect to the store.
        </div>

        <div class="d-flex justify-content-center">
            <button class="mt-4 btn btn-primary btn-sm text-uppercase clickfx" @click="$parent.view('setup')">Start Over</button>
        </div>

    </div>

</template>

<script>
import { useUserStore,useGameStore } from '@/gamestore.js';


export default {
    components: { 
    },
    data: () => ({  
        userStore: useUserStore(),
        gameStore: useGameStore(),
    }),
    methods: {

    },
    mounted() {
        this.$root.networkStatus();

        //this.$root.refreshIAP();
        // if ( this.IAP.owned ) {
        //     location.reload();
        // }
    },
}

</script>

<style>

</style>