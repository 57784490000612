<template>

    <div class="bg-white" id="overlay-about">

        <div class="p-2 text-start">
            <h3 class="text-primary">How to Play</h3>
            <ul class="list-group list-group-flush text-start">
                <li class="list-group-item">
                    You can click either the blank or the word first, or you can drag the word onto the blank.
                    <br>
                    <img class="m-1" style="width:30rem;max-width:330px;" src="@/assets/gfx/justsaying-howtoplay.gif" />
                </li>
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-3 col-md-1">
                            <img class="m-1" style="width:100%;" src="@/assets/gfx/justsaying-playername.png" />
                        </div>
                        <div class="col">
                            Click the player name to edit and create new players.
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-3 col-md-1">
                            <img class="icon-shield icon-small" :src="userStore.user.tools.unmask.icon" />
                        </div>
                        <div class="col">
                            <span class="text-primary">{{ userStore.user.tools.unmask.name }}</span>
                            <br>
                            {{ userStore.user.tools.unmask.description  }}
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-3 col-md-1">
                            <img class="icon-shield icon-small" :src="userStore.user.tools.shield.icon" />
                        </div>
                        <div class="col">
                            <span class="text-primary">{{ userStore.user.tools.shield.name }}</span>
                            <br>
                            {{ userStore.user.tools.shield.description  }}
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-3 col-md-1">
                            <img class="icon-shield icon-small" :src="userStore.user.tools.spotlight.icon" />
                        </div>
                        <div class="col">
                            <span class="text-primary">{{ userStore.user.tools.spotlight.name }}</span>
                            <br>
                            {{ userStore.user.tools.spotlight.description  }}
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-3 col-md-1">
                            <img class="icon-shield icon-small" :src="userStore.user.tools.wand.icon" />
                        </div>
                        <div class="col">
                            <span class="text-primary">{{ userStore.user.tools.wand.name }}</span>
                            <br>
                            {{ userStore.user.tools.wand.description  }}
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <hr/>
        <div class="p-2 text-start">
            <h4 class="text-primary">Reset Data</h4>
            <p>
                To delete all player data &mdash; 
                <button href="#" class="btn btn-primary btn-sm ext-decoration-none fs-6" @click="resetData()">Click here</button>
            </p>
        </div>

        <hr/>
        <div class="p-2 text-start">
            <h4 class="text-primary">Acknowledgments</h4>
            <ul class="list-group list-group-flush text-start">
                <li class="list-group-item">
                    Background music - <a target="_blank" href="https://freemusicarchive.org/music/Origami_Repetika/the-happy-puppy-collection">Origami Repetika</a> and <a target="_blank" href="https://freemusicarchive.org/music/zane-little/a-bag-of-chips">Zane Little</a>
                </li>
                <li class="list-group-item">
                    <a target="_blank" href="https://www.flaticon.com/free-animated-icons/animals" title="animals animated icons">Animals animated icons created by Freepik - Flaticon</a>
                </li>
                <li class="list-group-item">
                    Game testing and consulting - <a target="_blank" href="https://adamosterweil.com/">Adam Osterweil</a>
                </li>
            </ul>
        </div>

        <div class="mt-2 mb-1 d-flex justify-content-center">
            <button class="mt-2 btn btn-primary text-uppercase" type="button" @click="this.$root.view('setup');">close</button>
        </div>

    </div>

    <alert-dialog ref="alertDialog"></alert-dialog>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
</template>

<script>
import { useUserStore,useGameStore } from '@/gamestore.js';
import alertDialog from '@/components/alertdialog.vue';
import confirmDialog from '@/components/confirmdialog.vue';

export default {
    components: { 
        alertDialog, confirmDialog
    },
    data: () => ({  
        userStore: useUserStore(),
        gameStore: useGameStore(),
    }),
    methods: {
        async resetData() {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Reset All Data',
                message: 'Are you sure you want to permanently remove all players?',
                okButton: 'Reset',
                cancelButton: 'No',
            });
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.userStore.resetUsers();
                this.$root.view('setup');
            } else {
                //bail
            }
        },         
    }
}
</script>

<style>
#overlay-about {
    height: 100%;
    overflow-y:auto;
}
</style>