<template>

  <div class="container p-0">
    <div class="col-12 position-relative" :class="{ 'busted' : page=='bust' }" id="app-content">

      <div class="mt-0 px-3" id="app-body">
        <div id="bg-overlay">
          <component :is="currentView" :key="componentKey" />
        </div>
      </div>

      <img v-show="0" src="@/assets/gfx/logo1-tts.png" />
      <alert-dialog ref="alertDialog"></alert-dialog>
      <confirm-dialog ref="confirmDialog"></confirm-dialog>

      <myfooter v-if="showFooter()" />

    </div>

  </div>

</template>

<script>
import { App as CapApp } from '@capacitor/app';
import { Network } from '@capacitor/network';
import slider from "vue3-slider";
import myfooter from "@/components/footer.vue";
import about from "@/views/about.vue";
import home from "@/views/home.vue";
import setup from "@/views/setup.vue";
import player from "@/views/player.vue";
import source from "@/views/source.vue";
import play from "@/views/play.vue";
import shop from "@/views/shop.vue";
import bust from "@/views/bust.vue";
import upgrade from "@/views/upgrade.vue";
import upgrade_restore from "@/views/upgrade_restore.vue";
import paythanks from "@/views/paythanks.vue";
import offline from "@/views/offline.vue";
import notfound from "@/views/404.vue";
import avatarLib from '@/avatars.js';
import sfxLib from '@/sfx.js';
import gfxLib from '@/gfx.js';
import { useUserStore,useGameStore } from '@/gamestore.js';
import alertDialog from '@/components/alertdialog.vue';
import confirmDialog from '@/components/confirmdialog.vue';

const remoteAjax = "https://quotes.playjustsaying.com/backend/ajax.php";

const routeMap = {
  about: about,
  home: home,
  setup: setup,
  player: player,
  source: source,
  play: play,
  shop: shop,
  bust: bust,
  upgrade: upgrade,
  upgrade_restore: upgrade_restore,
  paythanks: paythanks,
  notfound: notfound,
  offline: offline,
};

function decodeBase64(base64) {
    const text = atob(base64);
    const length = text.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
        bytes[i] = text.charCodeAt(i);
    }
    const decoder = new TextDecoder();
    return decoder.decode(bytes);
}

document.addEventListener('deviceready', onDeviceReady);
let IAP = {};
const IAP_ProductId = 'jsunlimited';
let IAP_Platform = '';
let IAP_paycomplete = 0;

function onDeviceReady() {
  console.log('ondeviceready');
  const {store, ProductType, Platform, LogLevel} = CdvPurchase;
  //store.verbosity = LogLevel.DEBUG;
  console.log(Capacitor.getPlatform());
  if ( Capacitor.getPlatform() == 'android' ) {
    IAP_Platform = Platform.GOOGLE_PLAY;
  } else {
    IAP_Platform = Platform.APPLE_APPSTORE;
  }
  store.register([{
    type: CdvPurchase.NON_CONSUMABLE,
    id: IAP_ProductId,
    platform: IAP_Platform,
  }]);

  store.when()
    .productUpdated(refreshIAP)
    .approved(transaction => {
      if (transaction.products && transaction.products[0] && transaction.products[0].id === 'com.bordella.justsayingmq') {
        transaction.finish();
        return;
      }
      if (transaction.state === 'approved') {
        finishIAP(transaction);
      }
    });
  store.initialize([IAP_Platform]);
  store.ready(readyIAP);
}

function readyIAP() {
  console.log('IAP ready');
  if ( Capacitor.getPlatform() == 'ios' ) {
    refreshIAP();
  }
}

function finishIAP(transaction) {
  console.log('finishIAP', transaction);

  localStorage.setItem("IAP_isPaid", "1");

  if ( IAP_paycomplete == 1 ) {
    IAP_paycomplete = 0;
    
    transaction.finish();
    console.log('finishedIAP');

    location="/?paythanks";
  }
}

function refreshIAPStore() {
  console.log('refreshIAPStore');
  const {store, ProductType, Platform} = CdvPurchase;
  store.update();
}

function refreshIAP() {
  console.log('refreshIAP', IAP_Platform, IAP_ProductId);
  const {store, ProductType, Platform} = CdvPurchase;
  //console.log('products', store.products);
  IAP = store.get(IAP_ProductId, IAP_Platform);
  console.log('IAP data', IAP.owned, JSON.stringify(IAP));
  //console.log('canpurchase',IAP.canPurchase);
  //console.log(JSON.stringify(IAP.pricing));

  return;
}

window.shuffleArray = (ar) => {
  return ar.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value);
}

export default {
  components: {
    "vue3-slider": slider,
    "myfooter": myfooter,
    "confirmDialog": confirmDialog,
    "alertDialog": alertDialog,
  },
  data () {
    return {
      Network: Network,
      CapApp: CapApp,
      avatarLib: avatarLib.avatarLib,
      sfxLib: sfxLib.sfxLib,
      gfxLib: gfxLib.gfxLib,
      globalVolume: 100,
      globalVolumeShow: 0,
      globalTimer: 0,
      sfxObj: {
        bgTune: 0,
        bgTuneVol: 0.15,
        tmp: 0,
      },
      sfxPlaylist: {},
      ttsActive: 0,
      ttsForce: 0,
      logoGrow: 0,
      page: 'setup',
      remoteAjax: remoteAjax,
      userStore: useUserStore(),
      gameStore: useGameStore(),
      componentKey: Math.random(1,10000),
      IAP: IAP,
      viewHistory: [],
    }
  },
  mounted() {
    var self = this;
    // this.Network.addListener('networkStatusChange', status => {
    //   //{connected: true, connectionType: 'wifi'}
    //   //console.log('Network status changed', status);      
    //   if ( !status.connected && self.page != 'offline' ) {
    //     self.view('offline');
    //   }
    // });
    // this.networkStatus();

    if ( !this.userStore.users.hasOwnProperty('freeplays') ) {
      this.userStore.users.freeplays = 20;
    }
    
    this.globalVolume = this.userStore.users.globalVolume;
    

    this.CapApp.addListener('backButton', ({ canGoBack }) => {
      //App.exitApp();
      //console.log('back button', this.viewHistory);
      
      if ( !this.viewHistory.length ) {
        this.viewHistory = ['setup'];
      }
      
      var v = this.viewHistory[0];

      if ( v == 'play' ) {
        this.view('setup');
      } else {
        this.view(v);
      }
    });

    document.addEventListener("click", e => {
      if ( e.srcElement.classList.contains('clickfx') || e.srcElement.closest('a')?.classList.contains('clickfx') || e.srcElement.closest('button')?.classList.contains('clickfx') ) {
        this.playSfx(this.sfxObj.tmp,this.sfxLib.clickSound,{});
      }
    });

    if ( location.search == '?paythanks' ) {
      window.history.replaceState({}, document.title, "/");
      this.view('paythanks');
    }


  },
  computed: {
    currentView() {
      let component = routeMap[this.page.toLowerCase()];
      if (component) return component;
      return notfound;
    }
  },
  methods: {
    appDimensions: function() {
      return {
        screenHeight: document.body.offsetHeight,
        appHeight: document.getElementById('app')?document.getElementById('app').offsetHeight:0,
        topOffset: parseInt(getComputedStyle(document.body).getPropertyValue("padding-top")),
        bottomOffset: parseInt(getComputedStyle(document.body).getPropertyValue("padding-bottom")),
        leftOffset: parseInt(getComputedStyle(document.body).getPropertyValue("padding-left")),
        rightOffset: parseInt(getComputedStyle(document.body).getPropertyValue("padding-right")),
        footerHeight: document.getElementById('footer') ? document.getElementById('footer').offsetHeight:0,
      }
    },
    networkStatus: async function() {
      var status = await Network.getStatus();
      //console.log('Network status:', status);
      if ( !status.connected && this.page != 'offline' ) {
        this.view('offline');
      }
    }, 
    refreshIAP: function(){
      refreshIAP();
    },
    decodeBundle: function(bundle) {
      bundle = bundle.split("").reverse().join("");
      return JSON.parse(decodeBase64(bundle));
    },
    view: async function(v) {
      if ( v != 'setup' ) {
        this.logoGrow = 0;
      }

      if ( this.gameStore.currentGame.inProgress && v != 'offline' && v != 'play' && v != 'shop') {
        this.$root.playSfx(this.$root.sfxObj.tmp,this.$root.sfxLib.speech.pendown,{tts:1});
        const ok = await this.$refs.confirmDialog.show({
            title: 'Game In Progress',
            message: "You won't keep any coins you've earned so far in this round.<br>Leave the current game?",
            okButton: 'Yes',
            cancelButton: 'No',
        });
        if (ok) {
          if ( this.sfxObj.wordActivate ) {
            this.playSfx(this.sfxObj.wordActivate,this.sfxLib.wordActivate,{fadeOut:500});
          }
          
          this.gameStore.currentGame.inProgress = 0;
          this.playSfx(this.sfxObj.bgTune,'',{stop:1});
          this.clearGlobalTimer();
          this.updateViewHistory(this.page);
          this.page = v;
        } else {
          //bail
        }
      } else {
        if ( this.gameStore.currentGame.inProgress ) {
          this.sfxObj.bgTune.volume(this.sfxObj.bgTuneVol/3);
        } else {
          this.playSfx(this.sfxObj.bgTune,'',{stop:1});
        }
        this.clearGlobalTimer();
        this.updateViewHistory(this.page);
        this.page = v;
      }
    },
    updateViewHistory: function(page) {
      this.viewHistory = [page];
    },
    clearGlobalTimer: function() {
      if ( this.globalTimer ) {
        clearInterval(this.globalTimer);
        this.globalTimer = 0;
      }
    },
    kickoffIAP: function() {
      IAP_paycomplete = 1;
      IAP.getOffer().order();
    },
    showFooter: function() {
      if ( this.page == 'setup' ) {
        return true;
      } else {
        return false;
      }
    },
    isPaid: function() {
      //return false; //debug

      //console.log('isPaid', localStorage.getItem("IAP_isPaid"), this.userStore.users, Capacitor.isNativePlatform());
      
      if ( Capacitor.isNativePlatform() ) {
        if ( localStorage.getItem("IAP_isPaid") || this.userStore.users.paidMode == 'unlimited' ) {
          this.userStore.users.paidMode = 'unlimited';
          delete this.userStore?.users?.freeplays;
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    restart: function() {
      this.gameStore.resetGame({});
      this.view('setup');
    },
    shuffle(arr) {
      let shuffled = arr
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

      return shuffled;
    },
    numFormat: function(num) {
        return num.toLocaleString("en-US");
    },
    calcBonus: function(type) {
      if ( type == 'base' ) {
        return this.gameStore.bonus.diff[this.userStore.user.options.diff].factor * this.userStore.user.options.ppr;
      } else if ( type == 'base-perfect' ) {
        return this.gameStore.bonus.diff[this.userStore.user.options.diff].factor * this.userStore.user.options.ppr * this.gameStore.bonus.perfectGame;
      } else if ( type == 'immunity' ) {
        return this.userStore.user.tools.shield.score * this.userStore.user.options.ppr;
      } else if ( type == 'perfectsolve' ) {
        return this.gameStore.bonus.diff[this.userStore.user.options.diff].factor * this.gameStore.bonus.perfectSolve;
      }
    },
    calcPenalty: function() {
      return this.gameStore.penalty.diff[this.userStore.user.options.diff].tax * this.userStore.user.options.ppr;
    },
    calcAwards: function() {
      if ( !this.userStore.user.totals.perfectGames?.length ) { return false; }

      var bucket = {};
      for (let x = 0; x < this.userStore.user.totals.perfectGames.length; x++) {
        var diff = this.userStore.user.totals.perfectGames[x].diff;
        var ppr = this.userStore.user.totals.perfectGames[x].ppr;

        if ( !bucket.hasOwnProperty(diff) ) {
          bucket[diff] = {};
        }

        bucket[diff][ppr] = bucket[diff][ppr] + 1 || 1;
      }

      var awards = [];

      for (let x = 0; x < Object.keys(bucket).length; x++) {
        var k = Object.keys(bucket).toSorted()[x];
        for (let y = 0; y < Object.keys(bucket[k]).length; y++) {
          var p = Object.keys(bucket[k]).toSorted()[y];
          var qty = Math.floor( bucket[k][p] / this.gameStore.bonus.diff[k].award.wins );

          if ( qty ) {
            awards.push( {
              icon: this.gameStore.bonus.diff[k].award.icon,
              qty: qty,
              ppr: p,
            } );
          }
        }
        
      }

      //console.log('awards',awards);

      return awards;
    },
    calcAwardsTotal: function() {
      var awards = this.calcAwards();
      var total = 0;
      for (let x = 0; x < awards.length; x++) {
        total = total + awards[x].qty;
      }
      return total;
    },  
    isBust: function(score) {
      if ( this.userStore.user.totals.puzzles > 0 && score <= 0 ) {
        return true;
      } else {
        return false;
      }
    },
    toggleBGTune: function() {
      if ( this.sfxObj.bgTune ) {
        if ( this.userStore.user.globalVolumeShow ) {
            this.sfxObj.bgTune.mute(false);
        } else {
            this.sfxObj.bgTune.mute(true);
        }
      }

      this.userStore.user.globalVolumeShow = !this.userStore.user.globalVolumeShow;
    },
    playSfxList: function(plist,items) {
      for (let x = 0; x < items.length; x++) {
        items[x].options.plist = plist;
      }
      this.sfxPlaylist[plist] = {
        howlObj: {},
        playIdx: 0,
        items: items,
      };

      this.sfxPlaylist[plist].howlObj = this.playSfx(0,items[0].sound,items[0].options);
    },
    playSfx: function(sfxObj,sound,options){
      //console.log('playsfx',sfxObj,sound,options);
      if ( options && options.stop ) {
          if ( sfxObj) {
              sfxObj.stop();
          }
          return;
      }

      if ( options && options.fadeOut ) {
          if ( sfxObj) {
              if ( this.userStore.users.appPlatorm == 'ios' ) {
                sfxObj.stop();
              } else {
                sfxObj.fade(sfxObj.volume(),0,options.fadeOut);
              }
          }
          return;
      }

      if ( sound.hasOwnProperty('files') ) {
        var sound2 = sound.files[Math.floor(Math.random()*sound.files.length)];
        sound = sound2;
      }
      
      if ( sound ) {
          if ( sfxObj ) {
            sfxObj.stop();
          }

          if ( options.volume ) {
            sound.volume = options.volume;
          }

          var vol;
          
          if ( options.bgTune ) {
            //vol = this.sfxObj.bgTuneVol * ( Number(this.globalVolume) / 100 );
            vol = this.sfxObj.bgTuneVol;

            if ( this.userStore.user.globalVolumeShow ) {
              vol = 0;
            }
          } else {
            vol = (sound.volume ? Number(sound.volume) : 1);
          } 


          var self = this;
          sfxObj = new Howl({
              src: require('@/assets/sfx/' + sound.file),
              html5: true,
              format: ['mp3'],
              options: options,
              loop: sound.loop ? sound.loop : false,
              volume: vol,
              rate: sound.rate ? sound.rate : 1,
          }).on('play',function(id, options){
            if ( options && options.logoGrow ) {
              self.logoGrow = options.logoGrow;
            }
            if ( options && options.tts ) {
              self.ttsActive = 1;
            }
            if ( options && options.ttsforce ) {
              self.ttsForce = 1;
            }
          }).on('stop',function(id, options){
            if ( self.ttsActive && !self.ttsForce ) {
              self.ttsActive = 0;
              self.logoGrow = 2;
            }
          }).on('end',function(id, options){
            if ( self.ttsActive ) {
              self.ttsActive = 0;
              self.ttsForce = 0;
            }

            if ( options.callback ) {
              options.callback(self);
            }

            if ( options.plist ) {
              var idx = self.sfxPlaylist[options.plist].playIdx + 1;
            
              if ( idx <= self.sfxPlaylist[options.plist].items.length-1 ) {
                self.sfxPlaylist[options.plist].playIdx = idx;
                self.sfxPlaylist[options.plist].howlObj = self.playSfx(self.sfxPlaylist[options.plist].howlObj,self.sfxPlaylist[options.plist].items[idx].sound,self.sfxPlaylist[options.plist].items[idx].options);
              }
            }
          });


          if ( sound.seek ) {
              sfxObj.seek(sound.seek);
          }

          sfxObj.play();
      }

        return sfxObj;
      }, 
      randomAsset: function(obj) {
          var keys = Object.keys(obj);
          keys = shuffleArray(keys);
          return obj[keys[0]];
      },
  },


}
</script>

<style>
html, body { height: 100%; }

#app {
  --phrase-desc-h: 1px;
  --game-summary-h: 1px;
  --gameboard-h: 1px;
  --footer-h: 1px;
  
  font-family: 'Lilita One';
  height: 100%;
  /* min-height: -webkit-fill-available; */
  overflow: hidden;
  background-color: gray;
  background-image: url('@/assets/gfx/lines_texture1095.jpg');
  background-position: 0.5rem;
}

#app > .container {
  height: 100%;
}

#app-content {
  height: 100%;
  background-color: #FFF;
  background-image: url('@/assets/gfx/bg2.jpg');
}

#app-body {
  /* height: calc(100% - var(--safe-area-inset-bottom)); */
  height: 100%;
}

#bg-overlay {
  height: 100%;
  /* height: calc(100% - var(--safe-area-inset-bottom)); */
  background-color: rgba(255,255,255,0.8);
}

.marquee {
  transition:0.5s;
  overflow:hidden;
}

.busted {
  background-image: url('@/assets/gfx/moldy.png');
}

.logo {
  font-family: 'Luckiest Guy', cursive;
  font-size: 4rem;
}

#volumeUI {
  position: absolute;
  right: 1.25rem;
  top: -2rem;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}


</style>
